<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="举报人">
                <a-input placeholder="请输入举报人姓名" v-model="queryParam.complaintName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="联系电话">
                <a-input placeholder="请输入联系电话" v-model="queryParam.contactPhone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="addDefaultRegion"
                  @change="changeAddRegion"
                />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="小区">
                  <a-select
                    placeholder="请选择小区"
                    v-model="queryParam.communityId"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="complaintName" slot-scope="text, record">
          <div v-if="record.complaintMode == 0">{{ record.complaintName }}</div>
          <div v-else>******</div>
        </span>
        <span slot="complaintMode" slot-scope="text, record">
          <div v-if="record.complaintMode == 0">实名举报</div>
          <div v-else>匿名举报</div>
        </span>
        <span slot="complaintAddress" slot-scope="text, record">
          {{ record.areaCodeName }} {{ record.communityName }} {{ record.complaintAddress }}
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="24" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="seeInfo(record.keyId)">查看</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要删除此记录吗？" @confirm="delComplaintApi(record.keyId)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </s-table>
      <see-complaint ref="seecomplaint"></see-complaint>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { STable, Ellipsis } from '@/components'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import SeeComplaint from '@/views/complaint/components/SeeComplaint'
import { complaintList, delComplaint } from '@/api/complaint'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '80px'
  },
  {
    title: '举报人姓名',
    dataIndex: 'complaintName',
    scopedSlots: { customRender: 'complaintName' }
  },
  {
    title: '联系电话',
    dataIndex: 'contactPhone'
  },
  {
    title: '地址',
    dataIndex: 'complaintAddress',
    scopedSlots: { customRender: 'complaintAddress' }
  },
  {
    title: '举报方式',
    dataIndex: 'complaintMode',
    scopedSlots: { customRender: 'complaintMode' }
  },
  {
    title: '举报类型',
    width: '100px',
    dataIndex: 'complaintType'
  },
  {
    title: '举报内容',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' }
  },
  {
    title: '举报时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '120px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ReportList',
  components: {
    STable,
    Ellipsis,
    SeeComplaint
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addPhoneFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        complaintName: '',
        contactPhone: '',
        communityId: 0,
        areaCode: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return complaintList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: [],
      statusList: [],
      maintainTypeList: []
    }
  },
  created () {
    this.regionListApi()
  },
  watch: {
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        complaintName: '',
        contactPhone: '',
        communityId: 0,
        areaCode: 0
      }
      this.addDefaultRegion = []
    },
    seeInfo (id) {
      this.$refs.seecomplaint.dataInfoApi(id)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      // this.queryParam.provinceId = e[0] ? e[0] : 0
      // this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    delComplaintApi (id) {
      console.log(id)
      const _this = this
      delComplaint({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
